import * as L from 'leaflet'
import * as utils from './utils'

export default class LMapClickSimulation {
    private __clickTimeWindow = 150
    private __map!: L.Map
    private __upMoment = 0
    private __downMoment = 0
    private __downState = false
    private __clickDelayAction = utils.staticNoopClickDelay
    private __clickDelayHandler?: NodeJS.Timeout

    clickListener = utils.staticNoopClickDelay as (evt: L.LeafletMouseEvent) => void

    bind(map: L.Map) {
        this.__map = map
        map.on('mousedown', this.__handleMapMouseDownEvent)
        map.on('mouseup', this.__handleMapMouseUpEvent)
        map.on('mousemove', this.__handleMapMouseMoveEvent)
    }

    unbind() {
        this.__cancelClick()
        if (this.__map) {
            this.__map.off('mousedown', this.__handleMapMouseDownEvent)
            this.__map.off('mouseup', this.__handleMapMouseUpEvent)
            this.__map.off('mousemove', this.__handleMapMouseMoveEvent)
        }
    }

    private readonly __handleMapMouseDownEvent = (evt: L.LeafletMouseEvent) => {
        this.__downState = true
        this.__downMoment = Date.now()
        this.__cancelClick()

        const delay = this.__downMoment - this.__upMoment
        if (delay < this.__clickTimeWindow) {
            return
        }

        this.__clickDelayAction = () => {
            this.__clickDelayAction = utils.staticNoopClickDelay
            this.__clickDelayHandler = undefined
            this.__upMoment = 0
            this.__downMoment = 0
            this.clickListener(evt)
        }
    }

    private readonly __handleMapMouseUpEvent = () => {
        this.__downState = false
        this.__upMoment = Date.now()
        this.__clickDelayHandler = setTimeout(() => this.__clickDelayAction(), this.__clickTimeWindow)
    }

    private readonly __handleMapMouseMoveEvent = () => {
        if (this.__downState) {
            // dragging
            this.__cancelClick()
        }
    }

    private __cancelClick() {
        if (this.__clickDelayAction !== utils.staticNoopClickDelay) {
            if (this.__clickDelayHandler) {
                clearTimeout(this.__clickDelayHandler)
                this.__clickDelayHandler = undefined
            }
            this.__clickDelayAction = utils.staticNoopClickDelay
        }
    }
}
