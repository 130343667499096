import { ObservableArray } from 'wdc-cube'
import { DateFns } from 'src/utils/date-fns'
import * as hash from 'object-hash'

import {
    RangeOperator,
    type TextualSearchFilters,
    type SBCriteria,
    type DateTimeFilter
} from 'src/components/searchbox'

import { FilterEntryScope, OverlayScope } from '../ta_scopes'

type KeyOfFilters = keyof TextualSearchFilters

export default class HandleSearchFilterAppliedCtx {
    currentFilters(overlay: OverlayScope): TextualSearchFilters {
        const filters: TextualSearchFilters = {}

        const loadFilter = (
            src: ObservableArray<FilterEntryScope>,
            tgtIdAttr?: KeyOfFilters,
            tgtTxtAttr?: KeyOfFilters
        ) => {
            if (src.length === 0) {
                return
            }

            const ids: string[] = []
            const txts: string[] = []
            for (const entry of src) {
                entry.id.substring(0, 3) !== 'x::' && ids.push(entry.id)
                entry.label && txts.push(entry.label)
            }

            if (tgtIdAttr && ids.length) {
                filters[tgtIdAttr] = ids
            }
            if (tgtTxtAttr && txts.length) {
                filters[tgtTxtAttr] = txts
            }
        }

        loadFilter(overlay.livre.texts, undefined, 'texts')

        loadFilter(overlay.empresa.cnpjs, 'cnpjs', 'cnpjTexts')
        loadFilter(overlay.empresa.empresas, undefined, 'empresaTexts')
        loadFilter(overlay.empresa.cnaes, 'cnaes', 'cnaeTexts')
        loadFilter(overlay.empresa.empEnderecos, 'empEnderecos', 'empEnderecoTexts')
        loadFilter(overlay.empresa.empCeps, 'empCeps', 'empCepTexts')
        loadFilter(overlay.empresa.empLogradouros, 'empLogradouros', 'empLogradouroTexts')
        loadFilter(overlay.empresa.empBairros, 'empBairros', 'empBairroTexts')
        loadFilter(overlay.empresa.empMunicipios, 'empMunicipios', 'empMunicipioTexts')

        loadFilter(overlay.pessoa.pessoas, 'pessoas', 'pessoaTexts')
        loadFilter(overlay.pessoa.pesEnderecos, 'pesEnderecos', 'pesEnderecoTexts')
        loadFilter(overlay.pessoa.pesCeps, 'pesCeps', 'pesCepTexts')
        loadFilter(overlay.pessoa.pesLogradouros, 'pesLogradouros', 'pesLogradouroTexts')
        loadFilter(overlay.pessoa.pesBairros, 'pesBairros', 'pesBairroTexts')
        loadFilter(overlay.pessoa.pesMunicipios, 'pesMunicipios', 'pesMunicipioTexts')
        loadFilter(overlay.pessoa.pesCbos, 'pesCbos', 'pesCboTexts')

        loadFilter(overlay.cnes.cnes, 'estabelecimentos', 'estabelecimentoSaudeTexts')
        loadFilter(overlay.cnes.cnesEnderecos, 'cnesEnderecos', 'cnesEnderecoTexts')
        loadFilter(overlay.cnes.cnesCeps, 'cnesCeps', 'cnesCepTexts')
        loadFilter(overlay.cnes.cnesLogradouros, 'cnesLogradouros', 'cnesLogradouroTexts')
        loadFilter(overlay.cnes.cnesBairros, 'cnesBairros', 'cnesBairroTexts')
        loadFilter(overlay.cnes.cnesMunicipios, 'cnesMunicipios', 'cnesMunicipioTexts')

        loadFilter(overlay.socios.socios, 'socios', 'socioTexts')

        loadFilter(overlay.pessoaAndSocio.cpfs, 'cpfs', 'cpfTexts')
        loadFilter(overlay.pessoaAndSocio.cids, 'cids', 'cidTexts')
        loadFilter(overlay.pessoaAndSocio.atos, 'atos', 'atoTexts')
        loadFilter(overlay.pessoaAndSocio.atoCbos, 'atoCbos', 'atoCboTexts')
        loadFilter(overlay.pessoaAndSocio.espMeds, 'espMeds', 'espMedTexts')
        loadFilter(overlay.pessoaAndSocio.crms, 'crmCodes', 'crmTexts')
        loadFilter(overlay.pessoaAndSocio.crmUfs, 'crmUfs', 'crmUfTexts')

        loadFilter(overlay.operadoras.empOps, 'empOpsCodes', 'empOpsTexts')
        loadFilter(overlay.operadoras.prfOps, 'prfOpsCodes', 'prfOpsTexts')

        return filters
    }

    mergeFilters(a: TextualSearchFilters, b: TextualSearchFilters): TextualSearchFilters {
        const r: TextualSearchFilters = {}

        const mergeArray = (k: KeyOfFilters, v1: string[] | undefined, v2: string[] | undefined) => {
            const v = new Set<string>()

            if (v1 && v1.length > 0) {
                v1.forEach((e) => v.add(e))
            }

            if (v2 && v2.length > 0) {
                v2.forEach((e) => v.add(e))
            }

            if (v.size > 0) {
                r[k] = [...v]
            }
        }

        for (const attrName of new Set([...Object.keys(a), ...Object.keys(b)])) {
            const k = attrName as KeyOfFilters
            mergeArray(k, a[k], b[k])
        }
        return r
    }

    filterToOnlyIds(filter: TextualSearchFilters) {
        const r: TextualSearchFilters = {}
        const fs = (k: KeyOfFilters) => filter[k] && (r[k] = filter[k])
        fs('cnpjs')
        fs('empEnderecos')
        fs('empCeps')
        fs('empLogradouros')
        fs('empBairros')
        fs('empMunicipios')
        fs('pesEnderecos')
        fs('pesCeps')
        fs('pesLogradouros')
        fs('pesBairros')
        fs('pesMunicipios')
        fs('pesCbos')
        fs('cnesEnderecos')
        fs('cnesCeps')
        fs('cnesLogradouros')
        fs('cnesBairros')
        fs('cnesMunicipios')
        fs('socios')
        fs('cpfs')
        fs('cnaes')
        fs('cids')
        fs('atos')
        fs('atoCbos')
        fs('pessoas')
        fs('estabelecimentos')
        fs('espMeds')
        fs('crmCodes')
        fs('crmUfs')
        fs('empOpsCodes')
        fs('prfOpsCodes')
        return r
    }

    appendTextsIfNotPresent(tgt: TextualSearchFilters, src: TextualSearchFilters) {
        const fs = (k: KeyOfFilters) => {
            const tgtTexts = tgt[k] ?? []
            const srcTexts = src[k]
            if (tgtTexts && srcTexts) {
                const extraTextSet = new Set<string>()
                const curTextSet = new Set(tgtTexts)
                for (const text of srcTexts) {
                    if (text && !curTextSet.has(text)) {
                        extraTextSet.add(text)
                    }
                }
                if (extraTextSet.size > 0) {
                    tgtTexts.push(...extraTextSet)
                    tgt[k] = tgtTexts
                }
            }
        }
        fs('cnpjTexts')
        fs('empEnderecoTexts')
        fs('empCepTexts')
        fs('empLogradouroTexts')
        fs('empBairroTexts')
        fs('empMunicipioTexts')
        fs('pesEnderecoTexts')
        fs('pesCepTexts')
        fs('pesLogradouroTexts')
        fs('pesBairroTexts')
        fs('pesMunicipioTexts')
        fs('pesCboTexts')
        fs('cnesEnderecoTexts')
        fs('cnesCepTexts')
        fs('cnesLogradouroTexts')
        fs('cnesBairroTexts')
        fs('cnesMunicipioTexts')
        fs('empresaTexts')
        fs('cnpjTexts')
        fs('socioTexts')
        fs('cpfTexts')
        fs('cnaeTexts')
        fs('cidTexts')
        fs('atoTexts')
        fs('atoCboTexts')
        fs('pessoaTexts')
        fs('estabelecimentoSaudeTexts')
        fs('espMedTexts')
        fs('crmTexts')
        fs('crmUfTexts')
        fs('empOpsTexts')
        fs('prfOpsTexts')
    }

    preserveTextsWithoutId(tgt: TextualSearchFilters) {
        const fs = (idKey: KeyOfFilters, txtKey: KeyOfFilters) => {
            const idArray = tgt[idKey]
            const txtArray = tgt[txtKey]
            if (idArray && txtArray && idArray.length <= txtArray.length) {
                const newIdArray = txtArray.slice(idArray.length)
                if (newIdArray.length > 0) {
                    tgt[txtKey] = newIdArray
                } else {
                    delete tgt[txtKey]
                }
            }
        }
        fs('cnpjs', 'cnpjTexts')
        fs('empEnderecos', 'empEnderecoTexts')
        fs('empCeps', 'empCepTexts')
        fs('empLogradouros', 'empLogradouroTexts')
        fs('empBairros', 'empBairroTexts')
        fs('empMunicipios', 'empMunicipioTexts')
        fs('pesEnderecos', 'pesEnderecoTexts')
        fs('pesCeps', 'pesCepTexts')
        fs('pesLogradouros', 'pesLogradouroTexts')
        fs('pesBairros', 'pesBairroTexts')
        fs('pesMunicipios', 'pesMunicipioTexts')
        fs('pesCbos', 'pesCboTexts')
        fs('cnesEnderecos', 'cnesEnderecoTexts')
        fs('cnesCeps', 'cnesCepTexts')
        fs('cnesLogradouros', 'cnesLogradouroTexts')
        fs('cnesBairros', 'cnesBairroTexts')
        fs('cnesMunicipios', 'cnesMunicipioTexts')
        fs('socios', 'socioTexts')
        fs('cpfs', 'cpfTexts')
        fs('cnaes', 'cnaeTexts')
        fs('cids', 'cidTexts')
        fs('atos', 'atoTexts')
        fs('atoCbos', 'atoCboTexts')
        fs('pessoas', 'pessoaTexts')
        fs('estabelecimentos', 'estabelecimentoSaudeTexts')
        fs('espMeds', 'espMedTexts')
        fs('crmCodes', 'crmTexts')
        fs('crmUfs', 'crmUfTexts')
        fs('empOpsCodes', 'empOpsTexts')
        fs('esaOpsCodes', 'esaOpsTexts')
        fs('prfOpsCodes', 'prfOpsTexts')
    }

    applyFilterOnOverlay(
        overlay: OverlayScope,
        filter: TextualSearchFilters,
        listener: (scope: FilterEntryScope) => Promise<void>
    ) {
        const syncEntry = (
            tgt: ObservableArray<FilterEntryScope>,
            pIds: string[] | undefined,
            pTexts: string[] | undefined
        ) => {
            let i = 0

            const ids = pIds ?? []
            const texts = pTexts ?? []
            const maxLen = Math.max(texts.length, ids.length)
            while (i < maxLen) {
                let scope = tgt.at(i)
                if (!scope) {
                    scope = new FilterEntryScope()
                    scope.update = overlay.update
                    scope.onRemoveClick = listener.bind(scope, scope)
                    tgt.push(scope)
                }

                scope.id = ids[i] ?? `x::${i}`
                scope.label = texts.at(i) ?? ''
                i++
            }
            tgt.length = i
        }

        syncEntry(overlay.livre.texts, [], filter.texts)

        syncEntry(overlay.empresa.cnpjs, filter.cnpjs, filter.cnpjTexts)
        syncEntry(overlay.empresa.empresas, [], filter.empresaTexts)
        syncEntry(overlay.empresa.cnaes, filter.cnaes, filter.cnaeTexts)
        syncEntry(overlay.empresa.empEnderecos, filter.empEnderecos, filter.empEnderecoTexts)
        syncEntry(overlay.empresa.empCeps, filter.empCeps, filter.empCepTexts)
        syncEntry(overlay.empresa.empLogradouros, filter.empLogradouros, filter.empLogradouroTexts)
        syncEntry(overlay.empresa.empBairros, filter.empBairros, filter.empBairroTexts)
        syncEntry(overlay.empresa.empMunicipios, filter.empMunicipios, filter.empMunicipioTexts)

        syncEntry(overlay.pessoa.pessoas, filter.pessoas, filter.pessoaTexts)
        syncEntry(overlay.pessoa.pesEnderecos, filter.pesEnderecos, filter.pesEnderecoTexts)
        syncEntry(overlay.pessoa.pesCeps, filter.pesCeps, filter.pesCepTexts)
        syncEntry(overlay.pessoa.pesLogradouros, filter.pesLogradouros, filter.pesLogradouroTexts)
        syncEntry(overlay.pessoa.pesBairros, filter.pesBairros, filter.pesBairroTexts)
        syncEntry(overlay.pessoa.pesMunicipios, filter.pesMunicipios, filter.pesMunicipioTexts)
        syncEntry(overlay.pessoa.pesCbos, filter.pesCbos, filter.pesCboTexts)

        syncEntry(overlay.cnes.cnes, filter.estabelecimentos, filter.estabelecimentoSaudeTexts)
        syncEntry(overlay.cnes.cnesEnderecos, filter.cnesEnderecos, filter.cnesEnderecoTexts)
        syncEntry(overlay.cnes.cnesCeps, filter.cnesCeps, filter.cnesCepTexts)
        syncEntry(overlay.cnes.cnesLogradouros, filter.cnesLogradouros, filter.cnesLogradouroTexts)
        syncEntry(overlay.cnes.cnesBairros, filter.cnesBairros, filter.cnesBairroTexts)
        syncEntry(overlay.cnes.cnesMunicipios, filter.cnesMunicipios, filter.cnesMunicipioTexts)

        syncEntry(overlay.socios.socios, filter.socios, filter.socioTexts)

        syncEntry(overlay.pessoaAndSocio.cpfs, filter.cpfs, filter.cpfTexts)
        syncEntry(overlay.pessoaAndSocio.cids, filter.cids, filter.cidTexts)
        syncEntry(overlay.pessoaAndSocio.atos, filter.atos, filter.atoTexts)
        syncEntry(overlay.pessoaAndSocio.atoCbos, filter.atoCbos, filter.atoCboTexts)
        syncEntry(overlay.pessoaAndSocio.espMeds, filter.espMeds, filter.espMedTexts)
        syncEntry(overlay.pessoaAndSocio.crms, filter.crmCodes, filter.crmTexts)
        syncEntry(overlay.pessoaAndSocio.crmUfs, filter.crmUfs, filter.crmUfTexts)

        syncEntry(overlay.operadoras.empOps, filter.empOpsCodes, filter.empOpsTexts)
        syncEntry(overlay.operadoras.prfOps, filter.prfOpsCodes, filter.prfOpsTexts)
    }

    // :: Criteria

    currentCriteria(overlay: OverlayScope): SBCriteria {
        const criteria: SBCriteria = {}
        if (overlay.pessoaAndSocio.crmDataInscricao.length) {
            for (const entry of overlay.pessoaAndSocio.crmDataInscricao) {
                if (entry.value) {
                    criteria.pesCrmDataInscricao = criteria.pesCrmDataInscricao ?? []
                    criteria.pesCrmDataInscricao.push(entry.value as DateTimeFilter)
                }
            }
        }
        return criteria
    }

    mergeCriteria(a: SBCriteria, b: SBCriteria): SBCriteria {
        const r: SBCriteria = {}
        const pesCrmDataInscricao = mesclaArrays(b.pesCrmDataInscricao, a.pesCrmDataInscricao)
        pesCrmDataInscricao && (r.pesCrmDataInscricao = pesCrmDataInscricao)
        return r
    }

    applyCriteriaOnOverlay(
        overlay: OverlayScope,
        criteria: SBCriteria,
        listener: (scope: FilterEntryScope) => Promise<void>
    ) {
        if (criteria.pesCrmDataInscricao?.length) {
            let i = 0
            let j = 0
            while (i < criteria.pesCrmDataInscricao.length) {
                const src = criteria.pesCrmDataInscricao.at(i++)
                if (!src) {
                    continue
                }

                let fromDate: Date | undefined
                if (src.value) {
                    const value = DateFns.parseISO(src.value as string)
                    if (DateFns.isValid(value)) {
                        fromDate = value
                    }
                }

                let toDate: Date | undefined
                if (src.to) {
                    const value = DateFns.parseISO(src.to as string)
                    if (DateFns.isValid(value)) {
                        toDate = value
                    }
                }

                if (!fromDate && !toDate) {
                    continue
                }

                let filterScope = overlay.pessoaAndSocio.crmDataInscricao.at(j)
                if (!filterScope) {
                    filterScope = new FilterEntryScope()
                    filterScope.update = overlay.update
                    filterScope.onRemoveClick = listener.bind(filterScope, filterScope)
                    overlay.pessoaAndSocio.crmDataInscricao.push(filterScope)
                    console.log('overlay.pessoaAndSocio.crmDataInscricao.push:', { i, j })
                }
                filterScope.id = `crmDataInscricao::${j}`
                filterScope.value = src

                const dtFmt = 'dd/MM/yyyy'

                switch (src.operator) {
                    case RangeOperator.is:
                        if (fromDate) {
                            filterScope.label = `é igual a ${DateFns.format(fromDate, dtFmt)}`
                        }
                        break
                    case RangeOperator.is_not:
                        if (fromDate) {
                            filterScope.label = `não é igual a ${DateFns.format(fromDate, dtFmt)}`
                        }
                        break
                    case RangeOperator.is_blank:
                        filterScope.label = 'Não possui data'
                        break
                    case RangeOperator.is_not_blank:
                        filterScope.label = 'Possui data'
                        break
                    case RangeOperator.is_less_then:
                        if (fromDate) {
                            filterScope.label = `é menor que ${DateFns.format(fromDate, dtFmt)}`
                        }
                        break
                    case RangeOperator.is_less_then_or_equal_to:
                        if (fromDate) {
                            filterScope.label = `é menor ou igual a ${DateFns.format(fromDate, dtFmt)}`
                        }
                        break
                    case RangeOperator.is_greater_then:
                        if (fromDate) {
                            filterScope.label = `é maior a que ${DateFns.format(fromDate, dtFmt)}`
                        }
                        break
                    case RangeOperator.is_greater_then_or_equal_to:
                        if (fromDate) {
                            filterScope.label = `é maior ou igual a ${DateFns.format(fromDate, dtFmt)}`
                        }
                        break
                    case RangeOperator.in_range:
                        if (fromDate && toDate) {
                            filterScope.label = `está no período ${DateFns.format(fromDate, dtFmt)} a ${DateFns.format(
                                toDate,
                                dtFmt
                            )}`
                        }
                        break
                    case RangeOperator.out_of_range:
                        if (fromDate && toDate) {
                            filterScope.label = `está fora do período ${DateFns.format(
                                fromDate,
                                dtFmt
                            )} a ${DateFns.format(toDate, dtFmt)}`
                        }
                        break
                }

                j++
            }

            overlay.pessoaAndSocio.crmDataInscricao.length = j
        }
    }
}

function mesclaArrays<T>(a: T[] | null | undefined, b: T[] | null | undefined): T[] | undefined {
    const r: T[] = []

    const map = new Map<string, T>()

    const collect = (e: T) => {
        if (e) {
            const key = hash.MD5(e)
            if (!map.has(key)) {
                r.push(e)
                map.set(key, e)
            }
        }
    }

    if (a) {
        a.map(collect)
    }

    if (b) {
        b.map(collect)
    }

    return r.length > 0 ? r : undefined
}
