import * as L from 'leaflet'
import lodash from 'lodash'

import { Logger, Presenter } from 'wdc-cube'

import {
    type GeoLocation,
    type GeoBoundingBox,
    type FetchRequest,
    type FetchResponse,
    type Criteria,
    type LocalizacaoFilter,
    type LocationDescription
} from '../ta_types'
import { MapCardScope, ScopeDefaults } from '../ta_scopes'
import { TheActingPresenter } from '../ta_presenter'
import { TheActingService } from '../ta_service'

import * as utils from './utils'
import LMapClickSimulation from './lmap_click_simulation'
import BaseSelectionModeBehaviour from './selection_mode_behaviour'
import AreaSelectionModeBehaviour from './selection_mode_behaviour_area'
import RouteSelectionModeBehaviour from './selection_mode_behaviour_route'
import CircleSelectionModeBehaviour from './selection_mode_behaviour_circle'

const LOG = Logger.get('TcmPresenterForMap')

const service = TheActingService.singleton()

const MARK_CIRCLE_CLASSES = static_buildMarkCircleClasses()

export class TaPresenterForMap extends Presenter<MapCardScope, TheActingPresenter> {
    // Constructor

    constructor(owner: TheActingPresenter) {
        super(owner, owner.scope.mapCard)
        this.__parent = owner
        this.__areaSelectionBehaviour = new AreaSelectionModeBehaviour()
        this.__areaSelectionBehaviour.owner = this

        this.__routeSelectionBehaviour = new RouteSelectionModeBehaviour()
        this.__routeSelectionBehaviour.owner = this

        this.__circleSelectionBehaviour = new CircleSelectionModeBehaviour()
        this.__circleSelectionBehaviour.owner = this

        this.__selecionBehaviour = this.__routeSelectionBehaviour
    }

    // Instance

    private readonly __parent: TheActingPresenter

    readonly localizacaoCircles = new ObjectProperty<GeoLocation[]>(this, true)
    readonly localizacaoRoutes = new ObjectProperty<GeoLocation[]>(this, true)
    readonly localizacaoPolygons = new ObjectProperty<GeoLocation[]>(this, true)

    private __map: L.Map | null = null
    private __mapCenter = ScopeDefaults.getDefaultCenter()
    private __layerGroup: L.LayerGroup<unknown> | null = null
    private __loaded = false
    private __fetching = false
    private __ready = false
    private __dataChangedDuringFetch = false
    private __fetchRequestCount = 0
    private __requestHash = ''
    private __boundHash = ''
    private __dataset: LocationDescription[] = []
    private __clickSimulation = new LMapClickSimulation()

    private readonly __areaSelectionBehaviour: AreaSelectionModeBehaviour
    private readonly __routeSelectionBehaviour: RouteSelectionModeBehaviour
    private readonly __circleSelectionBehaviour: CircleSelectionModeBehaviour
    private __selecionBehaviour: BaseSelectionModeBehaviour
    private __drawRequestMoment = 0

    get keyboard() {
        return this.__parent.scope.keyboard
    }

    isInAdditiveMode(): boolean {
        return !this.__parent.scope.keyboard
    }

    async initialize() {
        this.__clickSimulation.clickListener = this.__handleMapClickEvent.bind(this)

        this.scope.update = this.update
        this.scope.onMapChanged = this.__handleMapChanged.bind(this)
        this.scope.onClearFilters = this.__handleClearFilters.bind(this)
        this.scope.onSetAreaMode = this.__handleSetAreaMode.bind(this)
        this.scope.onSetRouteMode = this.__handleSetRouteMode.bind(this)
        this.scope.onSetCircleMode = this.__handleSetCircleMode.bind(this)

        this.scope.slider.update = this.update
        this.scope.slider.onValueChanged = this.__handleSliderValueChanged.bind(this)

        this.__selecionBehaviour.setMode(15)
    }

    override release(): void {
        this.__clickSimulation.unbind()
        super.release()
    }

    /**
     * Dispara sempre que um this.update() for invocado
     * Roda uma única vez por ciclo de atualizacao.
     *
     * Ideal para calcular valores derivados
     */
    override onBeforeScopeUpdate() {
        this.scope.showClearButton = this.hasFilter()
        this.__selecionBehaviour.onBeforeScopeUpdate()
    }

    isLoaded() {
        return this.__loaded
    }

    clear() {
        this.__boundHash = ''
        this.__requestHash = ''
        this.__areaSelectionBehaviour.clear()
        this.__routeSelectionBehaviour.clear()
        this.__circleSelectionBehaviour.clear()
        this.update()
    }

    hasFilter() {
        return (
            this.__areaSelectionBehaviour.hasFilter() ||
            this.__routeSelectionBehaviour.hasFilter() ||
            this.__circleSelectionBehaviour.hasFilter()
        )
    }

    getBounds(): GeoBoundingBox {
        if (this.__map) {
            const bounds = this.__map.getBounds()
            return {
                top_left: {
                    lon: bounds.getWest(),
                    lat: bounds.getNorth()
                },
                bottom_right: {
                    lon: bounds.getEast(),
                    lat: bounds.getSouth()
                }
            }
        }

        return {
            top_left: {
                lon: 12.46876014482322,
                lat: -28.4765625
            },
            bottom_right: {
                lon: -40.380028402511826,
                lat: -76.46484375000001
            }
        }
    }

    public markReady() {
        this.__ready = true
    }

    public prepareCriteria(request: FetchRequest) {
        const localizacaoFilter: LocalizacaoFilter = {}

        let hasFilter = false

        this.localizacaoCircles.size &&
            ((hasFilter = true), (localizacaoFilter.circles = [...this.localizacaoCircles.values()]))

        this.localizacaoRoutes.size &&
            ((hasFilter = true), (localizacaoFilter.routes = [...this.localizacaoRoutes.values()]))

        this.localizacaoPolygons.size &&
            ((hasFilter = true), (localizacaoFilter.polygons = [...this.localizacaoPolygons.values()]))

        if (hasFilter) {
            const criteria: Criteria = request.criteria = request.criteria ?? {}
            criteria.localizacao = localizacaoFilter
        }
    }

    private async __handleClearFilters() {
        if (this.keyboard.ctrlKey) {
            this.__boundHash = ''
            this.__requestHash = ''
            this.__selecionBehaviour.clear()
        } else if (this.__selecionBehaviour.hasFilter()) {
            this.clear()
        }
        this.update()
        this.__renderElements(true)
    }

    private async __handleSetAreaMode() {
        const zoomValue = this.__map ? this.__map.getZoom() : 4
        this.__selecionBehaviour = this.__areaSelectionBehaviour
        this.__selecionBehaviour.setMode(zoomValue)
        this.__selecionBehaviour.onBeforeScopeUpdate()
        this.update()
    }

    private async __handleSetRouteMode() {
        const zoomValue = this.__map ? this.__map.getZoom() : 4
        this.__selecionBehaviour = this.__routeSelectionBehaviour
        this.__selecionBehaviour.setMode(zoomValue)
        this.__selecionBehaviour.onBeforeScopeUpdate()
        this.update()
    }

    private async __handleSetCircleMode() {
        const zoomValue = this.__map ? this.__map.getZoom() : 4
        this.__selecionBehaviour = this.__circleSelectionBehaviour
        this.__selecionBehaviour.setMode(zoomValue)
        this.__selecionBehaviour.onBeforeScopeUpdate()
        this.update()
    }

    private async __handleSliderValueChanged(value: number) {
        this.scope.slider.value = value
        if (this.__fetching) {
            this.__dataChangedDuringFetch = true
            this.__fetchRequestCount++
        } else {
            this.__selecionBehaviour.applyFilter()
            if (this.__selecionBehaviour.hasFilter()) {
                await this.__renderElements(true)
            }
        }
    }

    private async __handleMapChanged(map: L.Map | null) {
        const changed = this.__map !== map
        if (this.__map && changed) {
            this.__clickSimulation.unbind()
            this.__map.off('moveend')
            this.__map.off('zoomend')
            this.__selecionBehaviour.removeAllLayers()
        }

        this.__map = map
        if (map && changed) {
            this.__clickSimulation.bind(map)

            this.__selecionBehaviour.setZoom(map.getZoom())

            map.on('moveend', this.__onMapMoveEndEvent.bind(this))
            map.on('zoomend', this.__handleZoomEndEvent.bind(this))

            if (!this.__mapCenter) {
                this.__mapCenter = ScopeDefaults.getDefaultCenter()
            }
            if (this.__layerGroup) {
                this.__layerGroup.addTo(map)
                map.setView(this.__mapCenter, 4)
            } else {
                map.setView(this.__mapCenter, 4)
            }

            if (this.__dataset.length > 0) {
                this.plotPlaces()
            }

            this.__drawRequestMoment = Date.now()
            this.__areaSelectionBehaviour.draw(map)
            this.__routeSelectionBehaviour.draw(map)
            this.__circleSelectionBehaviour.draw(map)
        } 
    }

    plotPlaces() {
        this.scope.showClearButton = this.__selecionBehaviour.hasFilter()
        const leafletMap = this.__map
        if (!leafletMap) {
            return
        }

        if (this.__layerGroup) {
            this.__layerGroup.remove()
            this.__layerGroup = null
        }
        const layerGroup = L.layerGroup()

        let center = ScopeDefaults.getDefaultCenter()
        const southWest: L.LatLngLiteral = { ...center }
        const northEast: L.LatLngLiteral = { ...center }

        const dataset = this.__dataset
        if (dataset.length > 0) {
            const entryValuesIt = dataset[Symbol.iterator]()
            let entry = entryValuesIt.next()
            if (!entry.done) {
                const coordenadas = entry.value.location
                northEast.lng = southWest.lng = coordenadas.lon
                northEast.lat = southWest.lat = coordenadas.lat

                center.lat = coordenadas.lat
                center.lng = coordenadas.lon
            }

            while (!entry.done) {
                const { lon: lng, lat } = entry.value.location

                southWest.lng = Math.min(southWest.lng, lng)
                northEast.lng = Math.max(northEast.lng, lng)

                northEast.lat = Math.min(northEast.lat, lat)
                southWest.lat = Math.max(southWest.lat, lat)

                const names: string[] = []

                let markClassName = MARK_CIRCLE_CLASSES[0]
                if (entry.value.companies?.length) {
                    markClassName = MARK_CIRCLE_CLASSES[1]
                    names.push(...entry.value.companies)
                } else if (entry.value.professionals?.length) {
                    markClassName = MARK_CIRCLE_CLASSES[2]
                    names.push(...entry.value.professionals)
                }

                const marker = new L.Marker(
                    { lng, lat },
                    {
                        title: names.join('\n'),
                        icon: L.divIcon({ className: markClassName, iconSize: L.point(12, 12) })
                    }
                )
                layerGroup.addLayer(marker)

                entry = entryValuesIt.next()
            }
        }

        layerGroup.addTo(leafletMap)

        if (this.__boundHash === '') {
            if (dataset.length === 1) {
                leafletMap.flyTo(center, 15)
            } else if (dataset.length > 1) {
                const bounds = new L.LatLngBounds(southWest, northEast)
                center = bounds.getCenter()
                leafletMap.flyToBounds(bounds, { maxZoom: 15 })
            } else if (!this.__selecionBehaviour.hasFilter()) {
                leafletMap.flyTo(center, 4)
            }
        }

        this.__layerGroup = layerGroup
        this.__mapCenter = center
        this.__loaded = true
    }

    private __handleMapClickEvent(evt: L.LeafletMouseEvent) {
        this.__selecionBehaviour.addLocation({
            lat: evt.latlng.lat,
            lon: evt.latlng.lng
        })
        this.__renderElements(true).catch(LOG.caught)
    }

    private __handleZoomEndEvent() {
        if (!this.__map) {
            return
        }
        
        this.__selecionBehaviour.setZoom(this.__map.getZoom())
        this.__fetchIfBoundsChanged().catch(LOG.caught)
    }

    private async __onMapMoveEndEvent() {
        const drawWaitDeltaInMillis = Date.now() - this.__clickSimulation.upMoment
        if(drawWaitDeltaInMillis > 1000) {
            return
        }
        await this.__fetchIfBoundsChanged()
    }

    private async __fetchIfBoundsChanged() {
        if (!this.__ready) {
            return
        }

        const bounds = this.getBounds()

        const newRequestHash = utils.buildHash(bounds)
        if (this.__boundHash !== newRequestHash) {
            this.__boundHash = newRequestHash
            if (this.__fetching) {
                this.__fetchRequestCount++
            } else {
                const request: FetchRequest = {}
                this.__parent.buildQuery(request)
                await this.fetch(request)
            }
        }
    }

    private __newRequestHash(request: FetchRequest) {
        return utils.buildHash({ request })
    }

    async fetch(request: FetchRequest) {
        try {
            this.__ready = true

            request = lodash.cloneDeep(request)
            request.company = undefined
            request.professional = undefined
            request.onlyMap = true
            request.map = { bounds: this.getBounds(), limit: 1000 }

            if (request.criteria) {
                let hasContent = false
                const normCriteria: Record<string, unknown> = {}
                for(const [key, val] of Object.entries(request.criteria)) {
                    if(Object.hasOwn(request.criteria, key) && !lodash.isEmpty(val)) {
                        normCriteria[key] = val
                        hasContent = true
                    }
                }
                if (hasContent) {
                    request.criteria = normCriteria
                } else {
                    delete request.criteria
                }
            }

            if (request.filters) {
                let hasContent = false
                const normFilters: Record<string, unknown> = {}
                for(const [key, val] of Object.entries(request.filters)) {
                    if(Object.hasOwn(request.filters, key) && !lodash.isEmpty(val)) {
                        normFilters[key] = val
                        hasContent = true
                    }
                }
                if (hasContent) {
                    request.filters = normFilters
                } else {
                    delete request.filters
                }
            }

            const requestId = this.__fetchRequestCount
            const newRequestHash = this.__newRequestHash(request)
            const newBoundHash = utils.buildHash(this.getBounds())

            if (newRequestHash !== this.__requestHash) {
                this.__dataChangedDuringFetch = false
                this.__fetching = true

                const resp = await service.fetch(request)

                this.__handleResponse(resp, requestId, newRequestHash, newBoundHash)
            }
        } finally {
            this.__fetching = false
        }
    }

    private __handleResponse(resp: FetchResponse, requestId: number, newRequestHash: string, newBoundHash: string) {
        this.__drawRequestMoment = Date.now()
        this.__dataset = []
        if (resp.mapData) {
            this.__dataset = resp.mapData
        }

        this.__requestHash = newRequestHash
        this.__boundHash = newBoundHash
        this.plotPlaces()

        if (requestId !== this.__fetchRequestCount) {
            if (requestId > this.__fetchRequestCount) {
                this.__fetchRequestCount = requestId
            }

            this.__renderElements(this.__dataChangedDuringFetch).catch(LOG.caught)
        }
    }

    private async __renderElements(fetchData: boolean) {
        this.__boundHash = ''
        this.update()
        this.__selecionBehaviour.preparePoints()
        if (this.__map) {
            this.__drawRequestMoment = Date.now()
            this.__selecionBehaviour.draw(this.__map)
        }

        this.__areaSelectionBehaviour.applyFilter()
        this.__routeSelectionBehaviour.applyFilter()
        this.__circleSelectionBehaviour.applyFilter()

        if (fetchData) {
            this.__dataChangedDuringFetch = false
            await this.__parent.fetchData(true)
        }
    }
}

function static_buildMarkCircleClasses() {
    return [
        'gg-ue-red-circle',
        'gg-islamic-green-circle',
        'gg-duke-blue-circle',
        'gg-mughal-green-circle',
        'gg-weldon-blue-circle'
    ]
}

export class ObjectProperty<T> {
    private readonly __isInAdditiveMode: () => boolean
    private readonly __valueMap = new Map<string, T>()
    private readonly __toKey: (v: T) => string
    private readonly __debug

    constructor(mngr: TaPresenterForMap, alwaysAdditive = false, toKey?: (v: T) => string, debug = false) {
        this.__debug = debug
        this.__isInAdditiveMode = alwaysAdditive ? () => true : mngr.isInAdditiveMode.bind(mngr)
        this.__toKey = toKey ? toKey : (v) => JSON.stringify(v)
    }

    get size() {
        return this.__valueMap.size
    }

    toKey(v: T) {
        return this.__toKey(v)
    }

    isEmpty() {
        return this.__valueMap.size === 0
    }

    keys() {
        return this.__valueMap.keys()
    }

    values() {
        return this.__valueMap.values()
    }

    has(value: T | undefined) {
        if (lodash.isNil(value)) {
            return false
        }
        const newKey = this.__toKey(value)
        return this.__valueMap.has(newKey)
    }

    hasKey(key: string) {
        return this.__valueMap.has(key)
    }

    add(value: T | undefined) {
        const additive = this.__isInAdditiveMode()
        const valueMap = this.__valueMap
        if (lodash.isNil(value)) {
            if (!additive) {
                valueMap.clear()
            }
            return false
        }

        const newKey = this.__toKey(value)

        if (additive) {
            if (valueMap.has(newKey)) {
                valueMap.delete(newKey)
                return false
            }

            valueMap.set(newKey, value)
            return true
        } else if (valueMap.has(newKey)) {
            valueMap.delete(newKey)
            return false
        } else {
            valueMap.clear()
            valueMap.set(newKey, value)
            return true
        }
    }

    remove(value: T | undefined) {
        if (this.__debug) {
            console.debug('remove: ' + value)
        }
        if (lodash.isNil(value)) {
            return
        }
        const newKey = this.__toKey(value)
        this.__valueMap.delete(newKey)
    }

    clear() {
        if (this.__debug) {
            console.debug('clear')
        }
        this.__valueMap.clear()
    }
}
