import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { makeStyles } from 'tss-react/mui'
import * as fmtUtils from 'src/utils/formatter-utils'
import { ProfessionalCardScope, type ProfessionalRow } from '../ta_scopes'

import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import DownloadIcon from '@mui/icons-material/Download'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import { DataGridPro, GridColDef, GridValueGetterParams, GridRowParams, GridSortModel } from '@mui/x-data-grid-pro'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'

import { LocaleProvider } from 'src/utils/views'
import { ProfessionalSortModel } from '../ta_types'
import { lodash } from 'src/utils'

const LOG = Logger.get('TA-VIEW-PROF-CARD')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        alignSelf: 'stretch',
        flex: 1
    },
    viewShrunk: {
        //maxWidth: 500
    },
    viewExpanded: {
        //height: '100%'
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10
    },
    hSpacer: {
        flexGrow: 1
    },
    table: {
        alignSelf: 'stretch',
        flex: 1,
        flexBasis: 300
    }
})

const columns = static_buildColumns()
const rowsPerPageOptions = [10, 25, 50, 75, 100, 500]

export type ProfessionalCardViewProps = {
    className?: string
    scope: ProfessionalCardScope
}

class ProfessionalCardViewClass implements FCClassContext<ProfessionalCardViewProps> {
    // :: Fields

    scope!: ProfessionalCardScope

    // :: Emissors

    readonly emitExpandClick = () => this.scope.onExpandClick().catch(LOG.caught)
    readonly emitShrunkClick = () => this.scope.onShrunkClick().catch(LOG.caught)
    readonly emitDownloadClick = () => this.scope.onDownloadClick().catch(LOG.caught)

    readonly emitRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pageSize = parseInt(e.target.value, 10)
        this.scope.onPageSizeChange(pageSize).catch(LOG.caught)
    }

    readonly emitChangePage = (__e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        this.scope.onPageChange(newPage).catch(LOG.caught)
    }

    readonly emitRowClick = (params: GridRowParams<ProfessionalRow>) => {
        this.scope.onRowClick(params.row).catch(LOG.caught)
    }

    readonly emitSortModelChange = (gridSortModel: GridSortModel) => {
        const data: ProfessionalSortModel[] = []
        for (const item of gridSortModel) {
            data.push({
                field: item.field as ProfessionalSortModel['field'],
                sort: item.sort ?? 'asc'
            })
        }
        this.scope.onSortModelChange(data).catch(LOG.caught)
    }

    // :: Methods

    onSyncState({ scope }: ProfessionalCardViewProps) {
        this.scope = scope
    }

    render({ className, scope }: ProfessionalCardViewProps) {
        const classes = useStyles().classes

        const expandedCn = scope.expanded ? classes.viewExpanded : classes.viewShrunk

        return (
            <Paper className={clsx(classes.view, expandedCn, className)}>
                <div className={classes.title}>
                    <Typography variant="h6" gutterBottom>
                        Profissionais
                    </Typography>
                    <span className={classes.hSpacer} />
                    {scope.downloadEnabled && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="expand card"
                            onClick={this.emitDownloadClick}
                        >
                            <DownloadIcon />
                        </IconButton>
                    )}
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="expand card"
                        onClick={scope.expanded ? this.emitShrunkClick : this.emitExpandClick}
                    >
                        {scope.expanded && <CloseFullscreenRoundedIcon />}
                        {!scope.expanded && <LaunchRoundedIcon />}
                    </IconButton>
                </div>

                <DataGridPro
                    className={classes.table}
                    rows={scope.rows}
                    columns={columns}
                    sortingMode="server"
                    paginationMode="client"
                    getRowId={static_getRowId}
                    disableVirtualization
                    hideFooter
                    hideFooterPagination
                    onRowClick={this.emitRowClick}
                    onSortModelChange={this.emitSortModelChange}
                    disableColumnFilter={true}
                    initialState={{
                        sorting: {
                            sortModel: scope.sortBy
                        }
                    }}
                />
                {scope.working && <LinearProgress color="secondary" />}
                {scope.total > scope.pageSize && (
                    <TablePagination
                        component="div"
                        count={scope.total}
                        page={scope.page}
                        onPageChange={this.emitChangePage}
                        rowsPerPage={scope.pageSize}
                        rowsPerPageOptions={rowsPerPageOptions}
                        onRowsPerPageChange={this.emitRowsPerPageChange}
                    />
                )}
            </Paper>
        )
    }
}
export const ProfessionalCardView = classToFComponent(ProfessionalCardViewClass, React)
export default ProfessionalCardView

function static_getRowId(row: ProfessionalRow) {
    return row.id
}

function static_buildColumns(): GridColDef[] {
    const fns = LocaleProvider.get().fns
    return [
        {
            field: 'nome',
            headerName: 'Nome do profissional',
            width: 250
        },
        {
            field: 'crm',
            headerName: 'CRM',
            width: 100,
            valueFormatter: (params) => formatCRM(params.value)
        },
        {
            field: 'dataInscricao',
            headerName: 'Data Inscrição',
            type: 'date',
            width: 110,
            valueFormatter: (params) => fns.formatDate(params.value)
        },
        {
            field: 'situacao',
            headerName: 'Situação',
            width: 80
        },
        {
            field: 'especialidade',
            headerName: 'Especialidades/Área de Atuação',
            width: 160
        },
        {
            field: 'inscricao',
            headerName: 'Inscrição',
            width: 80
        },
        {
            field: 'dataPrimeiraInscricaoUF',
            headerName: 'Data Primeira Inscrição na UF',
            type: 'date',
            width: 110,
            valueFormatter: (params) => fns.formatDate(params.value)
        },
        {
            field: 'outrosCRMs',
            headerName: 'Outros CRMs',
            width: 160
        },
        {
            field: 'qualificacaoSocio',
            headerName: 'Qualificação do sócio',
            width: 180,
            flex: 1
        },
        {
            field: 'endereco',
            headerName: 'Endereço',
            width: 160,
            flex: 1
        },
        {
            field: 'telefone',
            headerName: 'Telefone',
            width: 160,
            valueGetter: (params: GridValueGetterParams) => fmtUtils.formatTelefone(params.row.telefone)
        },
        {
            field: 'celular',
            headerName: 'Celular',
            width: 160,
            valueGetter: (params: GridValueGetterParams) => fmtUtils.formatTelefone(params.row.celular)
        },
        {
            field: 'email',
            headerName: 'E-Mail',
            width: 160,
            flex: 1
        }
    ]
}

function formatCRM(value: unknown) {
    if (!lodash.isString(value)) {
        return
    }
    const slipPos = value.length - 2
    const code = value.substring(0, slipPos)
    const uf = value.substring(slipPos)
    return `${code}/${uf}`
}