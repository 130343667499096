import { Hash } from 'lang-utils'
import { type GeoLocation } from '../ta_types'

export { Hash }

export const NON_ACTIVE_SELECTION_COLOR = '#3388ff'
export const ACTIVE_SELECTION_COLOR = '#fb8500'
export const REGION_COLOR = '#AF8260'

export function staticNoopOther() {
    // NOOP
}

export function staticNoopClickDelay() {
    //console.debug('staticNoopClickDelay')
}


export function removeDuplicatedLocations(locationList: GeoLocation[]) {
    const locationMap = new Map<string, GeoLocation>()
    for (const entry of locationList) {
        locationMap.set(`${entry.lat}:${entry.lon}`, entry)
    }

    return [...locationMap.values()]
}

export function twoLocationToReactablePolygon(p1: GeoLocation, p2: GeoLocation) {
    const left = Math.min(p1.lat, p2.lat)
    const right = Math.max(p1.lat, p2.lat)
    const top = Math.min(p1.lon, p2.lon)
    const bottom = Math.max(p1.lon, p2.lon)

    return [
        {
            lat: left,
            lon: top
        },
        {
            lat: right,
            lon: top
        },
        {
            lat: right,
            lon: bottom
        },
        {
            lat: left,
            lon: bottom
        }
    ]
}

export function buildHash(bounds: unknown) {
    const sha512 = Hash.sha512()
    sha512.update(JSON.stringify(bounds))
    return sha512.digest('hex')
}

export function computeLine(p1: GeoLocation, p2: GeoLocation, offset: number) {
    const [dx, dy] = [p1.lat - p2.lat, p1.lon - p2.lon]
    const scale = offset / (dx * dx + dy * dy) ** 0.5
    const [ox, oy] = [-dy * scale, dx * scale]
    const p3 = { lat: ox + p1.lat, lon: oy + p1.lon }
    const p4 = { lat: ox + p2.lat, lon: oy + p2.lon }
    return [p3, p4]
}